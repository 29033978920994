<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/.el-select .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px
  }

  /deep/.el-input__inner {
    height: 30px;
    line-height: 30px;
    border-color: rgb(23, 118, 210);
  }

  /deep/ .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 195px;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header.vue";
  import {
    getFeeRecord,
    // tradeDetails
  } from "@/api/pay/record.js"
  import payConditionHeader from "@/components/payCondition-header";

  /**
   * 支付管理--记录
   */
  export default {
    components: {
      Layout,
      PageHeader,
      payConditionHeader


    },
    data() {
      return {
        title: "支付记录查询",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "支付管理",
            active: true
          }
        ],
        tableList: [],
        zffs: [{
            name: "微信",
            val: "01"
          },
          {
            name: "支付宝",
            val: "02"
          },
          {
            name: "易宝",
            val: "03"
          },
          {
            name: "人工收费_现金",
            val: "04"
          },
          {
            name: " 企业汇款_单位",
            val: "06"
          },
          {
            name: " 减免",
            val: "05"
          },
        ],
        exportTitle: "支付记录导出",
        module: "ZFJL",
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0,
          nf: new Date().getFullYear(),
          zffs: "",
          kssj: "",
          jssj: "",
          keyword: ""

        }
      };
    },
    methods: {
      getList() {
        getFeeRecord(this.pageData).then(res => {
          if (res.status) {
            if (res.status) {
              this.tableList = res.data
              this.pageData.total = res.total
            }
          }
        })
      },
      searchClick() { //搜索时先将分页的页码归1
        this.pageData.pageNum = 1; //当前页				
        this.getList() //渲染数据的函数
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
    },
    mounted() {
      this.getList()
    },

  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 500px;">
          <div class="card-body">
            <div class=" check-table-top">
              <div class="flexList" style="flex: 1;">
                <payConditionHeader :pageData="pageData"></payConditionHeader>
                <el-select class=" mr-2 " style="width: 10%;" v-model="pageData.zffs" clearable placeholder="支付方式"
                  size="small">
                  <el-option :value="item.val" :label="item.name" v-for="(item,i) in zffs" :key="i"></el-option>
                </el-select>
                <input placeholder="输入关键字" v-model="pageData.keyword" class="h30 form-control border-blue mr-2 w-15"
                  maxlength="50" />
                <button type="button" class="btn btn-info h30 flexList mr-2 w-sm " @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>

              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title:exportTitle, type: 'xlsx', module:module,condition:pageData})"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle, type:'dbf', module:module,condition:pageData})"><i
                    class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>

            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover  table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%;"> 序号</th>
                    <th style="width: 5%;">系统号</th>
                    <th>支付项目</th>
                    <th style="width: 5%;">姓名 </th>
                    <th style="width: 12%;">证件号码 </th>
                    <th style="width: 4%;">应缴 </th>
                    <th style="width: 4%;">实收 </th>
                    <th style="width: 5%;">支付状态</th>
                    <th style="width: 5%;">支付方式</th>
                    <th style="width: 9%;">订单交易号</th>
                    <th style="width: 10%;">支付时间</th>
                    <th style="width: 6%;">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item,index) in tableList" :key="index">
                    <td> {{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td>{{item.ddh}} </td>
                    <td>{{item.ywbt}}</td>
                    <td>
                      {{item.xm}}
                    </td>
                    <td>{{item.sfzjh}}</td>
                    <td>{{item.zje/100}}</td>
                    <td> {{item.sszje/100}}</td>
                    <td>
                      <div class="line1">已缴费</div>

                    </td>
                    <td>
                      <div class="line1" v-if="item.ywfs=='01'">微信</div>
                      <div class="line1" v-if="item.ywfs=='02'">支付宝</div>
                      <div class="line1" v-if="item.ywfs=='03'">易宝</div>
                      <div class="line1" v-if="item.ywfs=='04'">线下_现金</div>
                      <div class="line1" v-if="item.ywfs=='06'">线下_单位</div>
                      <div class="line1" v-if="item.ywfs=='05'">减免</div>
                      <div class="line1" v-if="item.ywfs=='07'">合利宝</div>
                    </td>
                    <td>{{item.jyh}}</td>
                    <td>{{item.createTime}}</td>
                    <td><a href=""><button type="button" class="btn btn-info w-xs h30"><i
                            class="iconfont mr-2 icon-antOutline-printer"></i>收据</button></a>
                    </td>
                  </tr>


                </tbody>

              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->


    <!-- 弹窗结束 -->
  </Layout>
</template>
